import { Card, Grid } from "@mui/material";
import { OutlinedBox } from "components/alove/OutlinedBox";
import { T } from "components/alove/Translator";
import { EndUser } from "models/EndUser";
import { Introduction } from "models/Introduction";
import React from "react";
import { tr } from "utils/globalsUtils";

const MDTypography = require("components/MDTypography/index.js").default;
const parentName = (profile, user) => {
  if (profile?.attributesValues["userType"] == "parent") {
    const parentName = user.customAttributes?.["custom:parentname"];
    if (!parentName) {
      return <T>parentNameNotSet</T>;
    }
    return (
      <MDTypography variant="body2">
        {parentName},{" "}
        <MDTypography component="span" variant="body2" style={{ fontWeight: "bold" }}>
          <T>Parent</T>
        </MDTypography>
      </MDTypography>
    );
  }
  return (
    <MDTypography variant="body2">
      {fullName(user)},{" "}
      <MDTypography component="span" variant="body2" style={{ fontWeight: "bold" }}>
        <T style={{ fontWeight: "bold" }}>Single</T>
      </MDTypography>
    </MDTypography>
  );
};
const fullName = (user: EndUser) => {
  return user?.firstName + " " + (user?.lastName || "");
};

const imagePolicy = (profile, user) => {
  const policy = profile.attributesValues?.image_policy?.at(0) || "onlyMM";
  return policy == "onlyMM" ? tr("notAllowed", {"name":user.first_name}):
  tr("allowed", {"name":user.first_name});
};

export const ContactDetails = ({ shRequest }: { shRequest: Introduction }) => {
  return (
    <Grid display="flex">
      <Grid item xs={6}>
        <MDTypography variant="body2" style={{ textDecoration: "underline" }}>
          {fullName(shRequest.initiatorUser)}
        </MDTypography>
        <MDTypography variant="body2">
          {parentName(shRequest.initiatorProfile, shRequest.initiatorUser)}
        </MDTypography>
        <MDTypography variant="body2">
          {shRequest.initiatorUser?.email} {shRequest.initiatorUser?.phoneNumber}
        </MDTypography>
        <MDTypography variant="body2">{imagePolicy(shRequest.initiatorProfile, shRequest.initiatorUser)}</MDTypography>
      </Grid>
      <Grid item xs={6}>
        <MDTypography variant="body2" style={{ textDecoration: "underline" }}>
          {fullName(shRequest.responderUser)}
        </MDTypography>

        <MDTypography variant="body2">
          {parentName(shRequest.responderProfile, shRequest.responderUser)}
        </MDTypography>
        <MDTypography variant="body2">
          {shRequest.responderUser?.email} {shRequest.responderUser?.phoneNumber}
        </MDTypography>
        <MDTypography variant="body2">{imagePolicy(shRequest.responderProfile, shRequest.responderUser)}</MDTypography>
      </Grid>
    </Grid>
  );
};
