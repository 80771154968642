import axios from "axios";
import { Brand } from "models/Brand";
import { networkGlobals } from "./networkUtils";

const getHost = () => {

    return  networkGlobals.serverHost;
};


const currentHost = getHost();

/** tost credentionals for auth */
export const getBrandsDO = async (host: string): Promise<Brand[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${currentHost}/api/brands/?host=${host}`,)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get brands:', error)
                resolve(error?.request);
                // reject(error);
            })
    })
}

export const brandLogo = (brand?: Brand): string => {
    return `https://alove-dev-assets.s3.amazonaws.com/public/uploaded/${brand?.folder}/png/app_logo.png`

}

/** get brands by name */
export const getBrandByNmeDO = async (host, name) => {
    return new Promise((resolve, reject) => {
        axios.get(`${currentHost}/api/brands/byName/?host=${host}&name=${name}`,)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get brands by name:', error)
                resolve(error?.request);
                // reject(error);
            })
    })
}

/** get contact by brand */
export const getContactByBrandDO = async (host, id) => {
    return new Promise((resolve, reject) => {
        axios.get(`${currentHost}/api/brands/contact/?host=${host}&id=${id}`,)
            .then(res => {
                resolve(res?.data);
            })
            .catch(error => {
                console.error('error from get brands by name:', error)
                resolve(error?.request);
                // reject(error);
            })
    })
}

/** get count open tickets */
export const getCountTickets = async (host, where) => {
    console.log('where: ', where);

    return new Promise((resolve, reject) => {
        axios.get(`${currentHost}/api/brands/countTickets/?host=${host}&where=${where}`,)
            .then(res => {
                resolve(res?.data?.rows);
            })
            .catch(error => {
                console.error('error from get brands by name:', error)
                resolve(error?.request);
                // reject(error);
            })
    })
}

/** update contact */
export const updateContactDO = async (host, data) => {
    try {
        const res = await axios.put(`${currentHost}/api/brands/contact`, { host, data });
        return res?.status;
    } catch (error) {
        console.error('Error updating role:', error);
        throw error;
    }
};

/** post contact */
export const postNewRoleDO = async (host, data) => {
    try {
        const res = await axios.post(`${currentHost}/api/brands/contact`, { host, data });
        return res?.status;
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};

/** delete contact */
export const deleteRoleDO = async (host, id) => {
    console.log('Deleting role with id:', id);
    try {
        const res = await axios.post(`${currentHost}/api/brands/deleteContact`, { host, id });
        console.log('Delete role response status:', res?.status);
        return res?.status;
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};

/** update brand status */
export const updateBrandStatusDO = async (host, status, id) => {
    try {
        const res = await axios.put(`${currentHost}/api/brands/brandStatus`, { host, status, id });
        return res?.status;
    } catch (error) {
        console.error('Error updating brand status:', error);
        throw error;
    }
};

/** get users per brand */
export const countUserForBrandDO = async (host) => {
    return new Promise((resolve, reject) => {
        axios.get(`${currentHost}/api/brands/countUsersPerBrand/?host=${host}`,)
            .then(res => {
                resolve(res?.data?.rows as any[]);
            })
            .catch(error => {
                console.error('error from get brands by name:', error)
                resolve(error?.request);
                // reject(error);
            })
    })
}