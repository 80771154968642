import axios from "axios";
import { makeRequest, networkGlobals } from "./networkUtils";
import { Attribute } from "models/Attribute";
import { getBucket } from "./networkUtils"

export const visibiltyOptions = {
  PUBLIC: {
    label: "Visible For Partners & SME",
    value: 1,
  },
  SME_VISIBLE: {
    label: "Visible For SME",
    value: 5,
  },
  PRIVATE: {
    label: "Visible Only For Me",
    value: 3,
  },
  HIDDEN: {
    label: "Hidden From All (Techincal attribute)",
    value: 4,
  },
};

let profileAttributes: {[key:string]: Attribute[]} = {}
export const loadAttributes = async () : Promise<Attribute[]> => {
  const brand = networkGlobals.brand.name.toLocaleLowerCase();
  const key = `${brand}-${getBucket()}`
  if (!profileAttributes[key]?.length) {
    profileAttributes[key] = await axios.get(
      // `https://${getBucket()}.s3.amazonaws.com/public/generated/${brand}/1.0.0/profile_attr_en.json`
      `https://alove-dev-assets.s3.amazonaws.com/public/generated/${brand}/1.0.0/profile_attr_en.json`
    ).then((res) => res.data);
    
  }
  return profileAttributes[key].map((attr) => Object.assign(new Attribute(), attr));
};

export const getAttributes = (host, brandID) => {
  return makeRequest(`attributes?brandID=${brandID}&host=${host}`)
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export const updatePrevalidation = (preValidation, bcid) => {
  return makeRequest(`attributes/batchContents/${bcid}/preValidation`, {
    method: "POST",
    body: preValidation,
  })
};

export const getBioPrefRelations = (attrName) =>  makeRequest(`attributes/${attrName}/relations`)


export const updateBioPrefRelations = (attrName, relations) => {
  return makeRequest(`attributes/${attrName}/relations`, {
    method: "POST",
    body: relations,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
}
