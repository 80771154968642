import { Card, Grid } from "@mui/material";
import CardItem from "components/alove/CardItem";
import ItemPageContainer from "layouts/itemPageContainer";
import { ScreenEvent, ScreenModel, ScreenType } from "models/Screen";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createScreenDO, deleteScreenDO, getScreen, saveScreenDO } from "utils/screenUtils";
import { ScreenForm } from "./components/screenForm";
import { ScreenPreview } from "./components/preview";
import { ScreenHeader } from "./components/screenHeader";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BoRole";
import { CardsForm } from "./components/cardsForm";
import { ScreenWorkflow } from "./components/workflow";

const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;

export const ScreensView = () => {
  const pageNumber = "3.6";
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { currentRole } = controller;
  const [screen, setScreen] = useState({
    type: ScreenType.Popup,
    event: ScreenEvent.standAlone,
  } as ScreenModel);
  const { id } = useParams();

  useEffect(() => {
    if (id && id !== "new") {
      getScreen(id).then((screen) => setScreenObject(screen));
    }
  }, [id]);

  const setScreenObject = (newScreen) => {
    setScreen(Object.assign(new ScreenModel(), newScreen));
  };

  const saveScreen = async () =>
    id === "new" ? createScreenDO(screen).then((screen) => navigate(-1)) : saveScreenDO(screen);

  const deleteScreen = async () => deleteScreenDO(screen).then(() => navigate(-1));

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle="Screens" />
      <ItemPageContainer
        navItems={[
          { label: "Screen settings", ref: "settings" },
          { label: "Edit screen", ref: "screen" },
          { label: "Workflow", ref: "workflow" },
        ]}
        actions={{
          actionsTitle: "Screen actions",
          itemName: `Information screen`,
          itemNumber: `#${screen.id}`,
          actions: [
            {
              title: "Save",
              onClick: saveScreen,
              variant: "contained",
            },
          ],
        }}
        itemStatus={{
          title: "Screen status",
          item: screen,
          isManager: true,
          changeStatus: async (newStatus) => {
            screen.statusId = newStatus;
            setScreenObject({ ...screen });
            await saveScreen();
          },
        }}
        itemHeader={<ScreenHeader screen={screen} setScreen={setScreenObject} />}
        deleteItem={{
          buttonText: "Delete Screen",
          contetnText: "Are you sure you want to delete this screen?",
          hidden: !currentRole.hasAccess(pageNumber, PermissionType.Delete),
          onDelete: deleteScreen,
        }}
      >
        <CardItem title="Edit screen" id="screen">
          <Grid container spacing={2}>
            <Grid xs={6}>
              {screen.type == ScreenType.CardsScreen ? (
                <CardsForm screen={screen} setScreen={setScreenObject} />
              ) : (
                <ScreenForm screen={screen} setScreen={setScreenObject} />
              )}
            </Grid>
            <Grid xs={6} container justifyContent="center">
              <ScreenPreview screen={screen} />
            </Grid>
          </Grid>
        </CardItem>
        <CardItem title="Workflow" id="workflow">
          <ScreenWorkflow screen={screen} setScreen={setScreenObject} />
        </CardItem>
      </ItemPageContainer>
    </DashboardLayout>
  );
};
