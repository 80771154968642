import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { setCurrentDialog, useMaterialUIController } from "context";

const MDButton = require("components/MDButton/index.js").default;

export interface DeleteItemProps {
  contetnText: string;
  buttonText: string;
  hidden?: boolean;
  onDelete: () => Promise<any>;
}

export const DeleteItem = (props: DeleteItemProps) => {
  const { onDelete, contetnText, buttonText } = props;
  const [controller, dispatch] = useMaterialUIController();

  return (
    <>
      <MDButton
        variant="text"
        color="error"
        onClick={() => {
          setCurrentDialog(dispatch, DialogType.DeleteWarning);
        }}
      >
        {buttonText}
      </MDButton>
      <AloveDialog
        dialogType={DialogType.DeleteWarning}
        dialogTitle="Warning"
        dialogBody={contetnText}
        dialogConfirm={buttonText}
        error
        handleConfirm={onDelete}
      />
    </>
  );
};
