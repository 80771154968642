import {
  Autocomplete,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Select,
} from "@mui/material";
import { ScreenEvent, ScreenModel, ScreenType } from "models/Screen";
import { camelCaseToCapitalizedWords } from "utils/globalsUtils";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { RootRef, styled } from "@material-ui/core";
import { useEffect } from "react";
import { ImageUploader } from "components/alove/ImageUploader";

const MDInput = require("components/MDInput/index.js").default;
const MDButton = require("components/MDButton/index.js").default;
const MDTypography = require("components/MDTypography/index.js").default;


export const ScreenForm = ({ screen, setScreen }: { screen: ScreenModel; setScreen: any }) => {
  useEffect(() => {
    if (!screen.fieldsOrder) {
      setScreen({
        ...screen,
        fieldsOrder: [
          "image",
          "title",
          "subtitle",
          "body",
          "button1Title",
          "button2Title",
          "cancelTitle",
          "note",
        ],
      });
    }
  }, [screen]);

  const onDragEnd = (result) => {
    let itemsNew = screen.fieldsOrder.filter((field, index) => index !== result.source.index);
    itemsNew.splice(result.destination.index, 0, screen.fieldsOrder[result.source.index]);
    setScreen({ ...screen, fieldsOrder: itemsNew });
  };

  const item = (key, child, index) => {
    return (
      <Draggable key={key} draggableId={key} index={index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <ListItem>
              <Grid
                container
                my={1}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Grid item xs={1} xl={1}>
                  <ListItemIcon>
                    <MenuIcon />
                  </ListItemIcon>
                </Grid>
                <Grid item xs={11}>
                  {child}
                </Grid>
              </Grid>
            </ListItem>
          </div>
        )}
      </Draggable>
    );
  };

  const elements: { [key: string]: React.ReactNode } = {
    image: (
        <ImageUploader
          initImage={screen.image || ""}
          onUploaded={(image: string) => setScreen({ ...screen, image })}
        />
    ),
    title: (
      <MDInput
        fullWidth
        label="Screen Title"
        value={screen.title || ""}
        onChange={(e: any) => setScreen({ ...screen, title: e.target.value })}
      />
    ),
    subtitle: (
      <MDInput
        fullWidth
        label="Screen Subtitle"
        value={screen.subtitle || ""}
        onChange={(e: any) => setScreen({ ...screen, subtitle: e.target.value })}
      />
    ),
    body: (
      <MDInput
        fullWidth
        label="Screen Body"
        value={screen.body || ""}
        multiline
        rows={10}
        onChange={(e: any) => setScreen({ ...screen, body: e.target.value })}
      />
    ),


    button1Title: (
      <MDInput
        fullWidth
        label="Button 1 Title"
        value={screen.button1Title || ""}
        onChange={(e: any) => setScreen({ ...screen, button1Title: e.target.value })}
      />
    ),
    button2Title: (
      <MDInput
        fullWidth
        label="Button 2 Title"
        value={screen.button2Title || ""}
        onChange={(e: any) => setScreen({ ...screen, button2Title: e.target.value })}
      />
    ),
    cancelTitle: (
      <MDInput
        fullWidth
        label="Cancel Button Title"
        value={screen.cancelTitle || ""}
        onChange={(e: any) => setScreen({ ...screen, cancelTitle: e.target.value })}
      />
    ),
    note: (
      <MDInput
        fullWidth
        label="Additional Text"
        value={screen.note || ""}
        onChange={(e: any) => setScreen({ ...screen, note: e.target.value })}
      />
    ),
  };

  return (
    <Grid container flexDirection="column" p={4} gap={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <RootRef rootRef={provided.innerRef}>
                <List sx={{}}>
                  {screen.fieldsOrder?.map((field, index) => {
                    return item(field, elements[field], index);
                  })}
                  {provided.placeholder}
                </List>
              </RootRef>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};
