import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { getQuestionnaireStats } from "utils/enduserUtils";
import { formatDate } from "utils/globalsUtils";

export const Predictorsetails = ({ profileId }: { profileId: string }) => {
  const [qStats, setQStats] = useState([]);
  useEffect(() => {
    if (!profileId) return;
    getQuestionnaireStats( profileId).then((res) => {
      setQStats(res);
    });
  }, [profileId]);

  const columns:GridColDef[] = [
    {
      field: "display_name",
      headerName: "Questionnaire",
      flex: 1,
    },
    {
      field: "completion_rate",
      headerName: "Progress",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (v,row) => row.is_completed ? "100%" : `${Math.floor(v * 100)}%`,
    
    },
    {
      field: "is_completed",
      headerName: "Completed",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: (v) => (v ? "✔️" : "❌"),
    },
    {
      field: "completed_at",
      headerName: "Last Answered",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueFormatter: formatDate
    },
  ];

  return <DataGridPremium rows={qStats} columns={columns} autoHeight hideFooter />;
};
