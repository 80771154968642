import { capitalize } from "@mui/material";
import { Statusable } from "./BoUser";

export enum ScreenType {
  Popup = 0,
  Curtain = 1,
  FullScreen = 2,
  SliderScreen = 3,
  CardsScreen = 4,
}

export enum ScreenEvent {
  afterApprove = "afterApprove",
  popupUpdate = "popupUpdate",
  profileOpen = "profileOpen",
  addChild = "addChild",
  textLink = "textLink",
  welcome = "welcome",
  standAlone = "standAlone",
  additionalInfo = "additionalInfo",
}

export enum NextScreenEvent {
  genericScreen = "genericScreen",
  appScreen = "appScreen",
}

export enum ScreenStatus {
  draft = 1,
  active = 2,
  deactive = 3,
}

export type ScreenStyles = {
  button1Color: string;
  button2Color: string;
  isCancelButtonText: boolean;
  titleFontSize: number;
};

export interface ScreenCard {
  title: string;
  body: string;
  image: string;
}

export class ScreenModel implements Statusable {
  id: string;
  name: string;
  event: ScreenEvent;
  title: string;
  subtitle: string;
  body: string;
  button1Title: string;
  button2Title: string;
  cancelTitle: string;
  note: string;
  image: string;
  brandId: number;
  param: string;
  type: ScreenType;
  fieldsOrder: string[];
  actionUrl: string;
  styles: ScreenStyles;
  statusId: ScreenStatus;
  categoryId: number;
  nextEventType: NextScreenEvent;

  get status(): string {
    return this.statusId?.toString();
  }

  get statusLabel(): string {
    return capitalize(ScreenStatus[this.statusId]);
  }

  get statusColor(): string {
    return this.statusId == ScreenStatus.active ? "success" : "error";
  }

  get statuses(): { key: string; value: string }[] {
    return Object.keys(ScreenStatus)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => ({
        key: key,
        value: capitalize(ScreenStatus[key]),
      }));
  }

  get cards(): ScreenCard[] {
    try {
      return JSON.parse(this.body);
    } catch (e) {
      return [];
    }
  }

  set cards(cards: ScreenCard[]) {
    this.body = JSON.stringify(cards);
  }
}

export const appPages = [
  "home",
  "loader",
  "welcome_intro",
  "login",
  "login_email",
  "login_phone",
  "login_otp",
  "onboarding_home",
  "questionnaires",
  "me",
  "bio_preferences",
  "questionnaires_start",
  "questionnaires_end",
  "home_lora_chat",
  "home_pre_login_lora",
  "pre_login_end",
  "public_profile",
  "public_profile_images",
  "privacy_policy",
  "predicator_feedback",
  "matchmaker_page",
  "matchmaker_info",
  "mood_update",
  "settings_profile_edit",
  "settings_preferences",
  "settings_parent",
  "settings_general",
  "settings_phone_change",
  "settings_phone_otp",
  "settings_phone_change_success",
  "settings_email_change",
  "settings_email_otp",
  "settings_email_change_success",
  "settings_language",
  "settings_router",
  "settings_support_center",
  "settings_notifications",
  "delete_account",
  "snooze_account",
  "snooze_account_details",

  "support_get_started_guide",
  "support_terms_of_use",
  "support_faq",
  "support_contact_us",
  "introductions",
  "potential_matches",
  "matches",
  "conversation",
  "conversation_call",
  "break_the_ice",
  "matches_accepted",
  "report",
  "report_details",
  "contact_us_option_technical_issues",
  "contact_us_option_subscription_and_payment",
  "contact_us_option_feedback",
  "contact_us_option_general_inquiry",
  "payments_intro",
  "payments_details",
  "payments_tokens",
  "payments_methods",
  "payments_features",
  "payments_history",
  "payments_add_card",
  "payments_pick_payment_method",
];
