import { formatDate } from "@fullcalendar/react";
import { ResumeIcon } from "components/alove/ResumeIcon";
import { isUrget, shouldColorBackground } from "utils/matchmakerUtils";
import { STATUSES } from "utils/matchmakerUtils";
import { T } from "components/alove/Translator";
import { fullName } from "utils/matchmakerUtils";
import { statusWithAction } from "utils/matchmakerUtils";
import { statusColors } from "utils/matchmakerUtils";

import { Tooltip, Avatar, Grid } from "@mui/material";

import UrgentSvg from "assets/images/icons/urgent.svg";
import { MMAvatar } from "components/alove/MMAvatar";
import { EngagedChip, TwoSizeChip } from "components/alove/Chips";
import { GridColDef } from "@mui/x-data-grid";
import { IntroductionStatus } from "models/Introduction";
import { tr } from "utils/globalsUtils";

const MDTypography = require("components/MDTypography").default;
const MDButton = require("components/MDButton").default;


const name = (user, request, isAdmin) => {
  return fullName(user, request.status, isAdmin);
};

const urgentBuilder = (request) =>
  isUrget(request) ? (
    <Tooltip  title={<T>urgentTooltip</T>}>
      <img style={{ width: "15px", height: "15px" }} src={UrgentSvg} />
    </Tooltip>
  ) : (
    <span style={{ width: "15px", height: "15px" }}></span>
  );

const coloredText = (text, request) => (
  <MDTypography
    variant="body2"
    style={{
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color:
        request.status == STATUSES.ENDED
          ? "grey"
          : [STATUSES.DATING, STATUSES.ENGAGED].includes(request.status)
          ? "#E44E9C"
          : isUrget(request)
          ? "red"
          : "black",
    }}
  >
    <T>{text}</T>
  </MDTypography>
);

const busyStatus = (request, column) => {
  if (request.status == STATUSES.ENDED) return null;
  const userStatus = request.statusReason?.initiatorStatus || request.statusReason?.responderStatus;
  const introStatus = request?.status <= 9 ? "first" : "second";
  return userStatus && request.status != 15
    ? tr(`${column}-${userStatus}-${introStatus}`)
    : null;
};

const shouldColorName = (request, intiator) =>
  (intiator && ["busy", "unmatch"].includes(request.statusReason?.initiatorStatus)) ||
  (!intiator && ["busy", "unmatch"].includes(request.statusReason?.responderStatus));

const dueClass = (request) =>
  [12, 13, 14, 8].includes(request.status) ? "hidden" : isUrget(request) ? "urgent" : "";


const statusText = (request) => (
  <span>
    <T>statusLabelTable{request.status}</T>{" "}
    <T>
      {request.status == STATUSES.DATING && request.statusReason?.dateNumber
        ? ` (${request.statusReason?.dateNumber}# date)`
        : ""}
    </T>
  </span>
);

const requestID = (request, isAdmin) =>
  isAdmin ? (
    request.mmBoUser?.first_name ? (
      <Grid
        style={{
          alignItems: "center",
          display: "flex",
          backgroundColor: "#f5f5f5",
          borderRadius: "15px",
          justifyContent: "left",
          marginTop: "5px",
        }}
        sx={{ p: 1, mr: 2 }}
        gap={1}
      >
        <Avatar src={request.mmBoUser?.avatar} sx={{ width: 24, height: 24 }} />
        <MDTypography
          variant="body2"
          style={{
            fontSize: "12px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {request.mmBoUser?.first_name}
        </MDTypography>
      </Grid>
    ) : (
      <T>noMMAssigned</T>
    )
  ) : (
    <Grid alignItems={"center"} style={{ height: "100%" }} container>
      <MDTypography variant="body2" fontSize="12px" color="grey">
        #{request.introductionId}
      </MDTypography>
    </Grid>
  );

const actionsCell = (request, action) => (
  <Grid container gap={1} justifyContent="center" alignItems="center" height="100%">
    {urgentBuilder(request)}
    <ResumeIcon resume={request.initiatorResume} enabled={request.status != STATUSES.NEW} />
    <ResumeIcon resume={request.responderResume} enabled={request.status != STATUSES.NEW} />
    <MDButton variant="outlined" color="dark" circular size="small" onClick={() => action(request)}>
      <T>{shouldColorBackground(request) ? "Dismiss" : "View"}</T>
    </MDButton>
  </Grid>
);

export const columns = (isAdmin:boolean, action): GridColDef[] => [
  {
    headerName: isAdmin ? "Matchmaker" : "Shidduch ID",
    field: "introductionId",
    flex: 1,
    renderCell: (params) => requestID(params.row, isAdmin),
    cellClassName: (params) => (!params.row.mmBoUser?.first_name ? "urgent" : ""),
  },
  {
    headerName: "Created",
    field: "created",
    renderCell: (params) => formatDate(params.row.created),
  },
  {
    headerName: "Due",
    field: "mustRespondUntil",
    renderCell: (params) =>
      isUrget(params.row)
        ? new Date(params.row.mustRespondUntil) < new Date()
          ? "Overdue"
          : "Tomorrow"
        : formatDate(params.row.mustRespondUntil),
    cellClassName: (params) => dueClass(params.row),
  },
  {
    headerName: "",
    field: "initiatorUserAvatar",
    width: 50,
    renderCell: (params) => (
      <Grid container alignItems="center" height="100%">
        <MMAvatar request={params.row} isFirst  isAdmin={isAdmin} />
      </Grid>
    ),
  },
  {
    headerName: "Intiator",
    field: "initiatorUser",
    flex: 1,
    renderCell: (params) => name(params.row.initiatorUser, params.row, isAdmin),

    cellClassName: (params) => (shouldColorName(params.row, true) ? "urgent" : ""),
  },
  {
    headerName: "",
    field: "responderUserAvatar",
    width: 50,
    renderCell: (params) => (
      <Grid container alignItems="center" height="100%">
        <MMAvatar request={params.row} isAdmin={isAdmin} />
      </Grid>
    ),
  },
  {
    headerName: "Responder",
    field: "responderUser",
    flex: 1,
    renderCell: (params) => name(params.row.responderUser, params.row, isAdmin),
  },
  {
    headerName: "Status",
    field: "status",
    flex: 1,
    renderCell: (params) =>
      busyStatus(params.row, "status") || (
      params.row.status == STATUSES.ENGAGED ? (
        <EngagedChip />
      ) : (
        <TwoSizeChip
          label={statusText(params.row)}
          variant="outlined"
          sizeoption={params.row.status == IntroductionStatus.DATING ||  STATUSES[params.row.status]?.length > 7 ? "wide" : "narrow"}
          color={statusColors[params.row.status]}
        />
      )),
    cellClassName: (params) => "urgent",
    align: "center",
  },
  {
    headerName: "actionRequired",
    field: "actionRequired",
    flex: 1,
    renderCell: (params) =>
      busyStatus(params.row, "action") ||
      (statusWithAction.includes(params.row.status) && (
        <T>{"statusActionTable" + params.row.status}</T>
      )),
    cellClassName: (params) =>
      busyStatus(params.row, "action") || isUrget(params.row) ? "urgent" : "",
  },
  {
    headerName: "Actions",
    field: "actions",
    width: 250,
    align: "center",
    renderCell: (params) => actionsCell(params.row, action),
  },
];
