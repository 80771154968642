import { Grid, Tooltip } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { AloveDialog, DialogType } from "components/alove/AloveDialog";
import { T } from "components/alove/Translator";
import { setCurrentDialog, useMaterialUIController } from "context";
import { Attribute } from "models/Attribute";
import { EndUser } from "models/EndUser";
import { IntroductionStatus } from "models/Introduction";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadAttributes } from "utils/attributesUtil";
import { getIntrosByFilter } from "utils/enduserUtils";
import {
  avatarUrl,
  formatDate,
  formtHeight,
  snakeCaseToCapitalizedWords,
} from "utils/globalsUtils";

const MDBox = require("components/MDBox/index.js").default;
const MDButton = require("components/MDButton/index.js").default;


export const Intros = ({ filter, profileId }: { filter: string; profileId: string }) => {
  const [intros, setIntros] = React.useState([]);
  const navigate = useNavigate();

  const [attributes, setAttributes] = useState([] as Attribute[]);
  const [controller] = useMaterialUIController();
  const { currentBrand, currentHost } = controller;
  useEffect(() => {
    if (!currentBrand?.id) return;
    loadAttributes().then((attrs) => {
      setAttributes(attrs);
    });
  }, [currentBrand]);

  useEffect(() => {
    if (!filter) return;
    setIntros([]);
    getIntrosByFilter(profileId, filter).then((res) => {
      res.forEach((r) => {
        r.pname = r.user?.first_name || r.introductionId;
        r.partnerProfile.attributesValues ||= {};
      });
      setIntros(
        res.concat(
          res.map((r) => ({
            ...r,
            pname: r.pname + "/Pref"
          }))
        )
      );
    });
  }, [filter]);

  const renderItem = (items, key) => {
    const attribute = attributes.find((a) => a.attr_name == key);
    const text = items
      ?.map((v) => attribute?.responses?.find((r) => r.id == v)?.txt || v)
      .join(", ");

    return text;
  };

  const columns: GridColDef[] = [
    {
      field: "image_uri",
      headerName: "",
      width: 50,
      renderCell: (params) =>
        params.row.pname.includes("Pref") ? (
          <MDBox />
        ) : (
          <Grid container alignItems="center" justifyContent="center" style={{ height: "100%" }}>
            <img
              src={avatarUrl(params.row.partnerId, params.row.imageUri)}
              alt="avatar"
              style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: "cover" }}
            />
          </Grid>
        ),
    },
    {
      field: "inspected",
      headerName: "Inspected",
      width: 50,
      valueFormatter: (v) => (v ? "✔️" : "❌"),
    },
    {
      field: "isFirst",
      headerName: "First",
      width: 50,
      valueFormatter: (v) => (v ? "✔️" : "❌"),
    },
    {
      field: "age",
      headerName: "Age",
      width: 50,
      valueFormatter: (v, row) =>
        row.pname.includes("Pref")
          ? row.partnerProfile.attributesValues.prefAge?.join(" - ")
          : Object.assign(new EndUser(), row.user).age,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 80,
      valueFormatter: (v, row) =>
        row.pname.includes("Pref")
          ? renderItem(row.partnerProfile.attributesValues.prefGender, "prefGender")
          : renderItem(row.partnerProfile.attributesValues.gender, "gender"),
    },
    {
      field: "livingPlace",
      headerName: "Location/Distance",
      width: 120,
      valueFormatter: (v, row) =>
        row.pname.includes("Pref")
          ? row.partnerProfile.attributesValues.prefHowFar
          : row.partnerProfile.attributesValues.livingPlace?.[0]?.city +
            ", " +
            row.partnerProfile.attributesValues.livingPlace?.[0]?.state,
    },
    {
      field: "marital",
      headerName: "Marital",
      flex: 1,
      valueFormatter: (v, row) =>
        row.pname.includes("Pref")
          ? renderItem(row.partnerProfile.attributesValues.prefMarital, "prefMarital")
          : renderItem(row.partnerProfile.attributesValues.marital, "marital"),
    },
    {
      field: "body_type",
      headerName: "Body Type",
      flex: 1,
      valueFormatter: (v, row) =>
        row.pname.includes("Pref")
          ? renderItem(row.partnerProfile.attributesValues.bodyTypePref, "bodyTypePref")
          : renderItem(row.partnerProfile.attributesValues.bodyType, "bodyType"),
    },
    {
      field: "height",
      headerName: "Height",
      width: 80,
      valueFormatter: (v, row) =>
        row.pname.includes("Pref")
          ? row.partnerProfile.attributesValues.heightPref?.map(formtHeight).join(" - ")
          : formtHeight(row.partnerProfile.attributesValues.height),
    },

    {
      field: "lastActive",
      headerName: "Last Active",
      width: 80,
      valueFormatter: (v, row) => (row.pname.includes("Pref") ? "" : formatDate(v)),
    },
    {
      field: "mustRespondUntil",
      width: 80,
      renderHeader: () => (
        <Tooltip title="Hours Left">
          <span>HL</span>
        </Tooltip>
      ),
      valueFormatter: (v, row) =>
        Math.max(0, Math.floor((new Date(v).getTime() - new Date().getTime()) / 1000 / 60 / 60)) ||
        "",
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <MDButton
          key="view"
          onClick={() => {
            navigate(`/introductions/${params.row.introductionId}`);
          }}
        >
          View
        </MDButton>,
      ],
    }
  ];

  return (
    <DataGridPremium
      rows={intros}
      columns={columns}
      getRowId={(row) => row.pname + row.introductionId}
      autoHeight
      getTreeDataPath={(row) => row.pname.split("/")}
      treeData={true}
      onRowClick={(params) => {
        navigate(`/endUsers/${params.row.user_id}`);
      }}
      hideFooter
      groupingColDef={{
        hideDescendantCount: true,
        width: 120,
      }}
    />
  );
};
