import React, { useState, useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import {
  questionsListDO,
  updateQuestionsOrderDO,
  publishQuestionnaireDO,
  updateQuestionStatusDO,
  questionnarieListDo,
} from "utils/questionsUtils";
import { postLogDO } from "utils/logsUtils"
import { addAlert, useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import AddEditQuestionText from "./textQuestionModal";
import { snakeCaseToCamelCase } from "utils/globalsUtils";
import { questionnaires } from "utils/questionsUtils";
import { TableColumnProps, TablePage } from "components/alove/TablePage";
import { formatDateTime } from "utils/globalsUtils";
import { TwoSizeChip } from "components/alove/Chips";
import { PermissionType } from "models/BoRole";
import { networkGlobals } from "utils/networkUtils";

const DashboardLayout = require("examples/LayoutContainers/DashboardLayout/index.js").default;
const DashboardNavbar = require("examples/Navbars/DashboardNavbar/index.js").default;
const MDSnackbar = require("components/MDSnackbar/index.js").default;
let tableForOrder = [] as any[];

function QuestionList() {

  const navigate = useNavigate();
  
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand,currentHost } = controller;

  const [seachValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [snackbar, setSnackbar] = useState({
    status: false,
    icon: "error",
    title : "",
    color: "error",
  });
  const [publishLoader, setPublishLoader] = useState(false);

  let { bname } = useParams();
  let [queryParameters] = useSearchParams();
  let questionaireID = questionnaires[snakeCaseToCamelCase(bname!)];
  const [lastOrdinal, setLastOrdinal] = useState(0);
  
  const [orderWasChanged, setOrderWasChanged] = useState(false);
  const [addEditModalOpenStatus, setAddEditModalOpenStatus] = useState(false);
  const [addEditModalOpenType, setAddEditModalOpenType] = useState("text");
  /** data for update modal */
  const [updateData, setUpdateData] = useState([] as any);

  const questionRidirectURL = "/questionnaires";

  const [view, setView] = React.useState("list");

  const columns:TableColumnProps<any>[] = [
    {
      headerName: "Type",
      field: "content_type_id",
      width: 15,
      renderCell: (params) =>
        params.row.content_type_id === 2 ? "Q" : params.row.content_type_id === 4 ? "A" : "T",
      align: "center",
    },
    {
      headerName: "#",
      field: "id",
      width: 70,
      align: "center",
      valueFormatter: (v,row:any) => row.qid || row.bcid
    },
    {
      headerName: "Name",
      field: "text",
      flex: 4,
    },
    {
      headerName: "Core",
      field: "is_core",
      width: 15,
      type: "boolean",
      align: "center",
    },
    {
      headerName: "Updated",
      field: "updated",
      renderCell: (params) => formatDateTime(params.row.updated),
      flex: 1,
    },
    {
      headerName: "Status",
      field: "content_state_id",
      renderCell: (params) => statusElement(params.row),
      flex: 1,
      align: "center",
    },
  ];

  const handleChangeStatus = async (event, nextView, id) => {
    console.log("nextView: ", nextView);
    console.log("event: ", event);
    console.log("id: ", id);
    if (nextView) {
      const changeStatusResp = await updateQuestionStatusDO(networkGlobals.host, id, nextView);
      console.log("changeStatusResp: ", changeStatusResp);
      if (changeStatusResp === 200) {
        getAndPrepareTableData();
        await postLogDO(
          "dev",
          networkGlobals.host,
          "question status update",
          "questionList",
          nextView,
          currentUser?.id ? currentUser?.id : 0
        );
        setSnackbar({
          ...snackbar,
          status: true,
          title: "Question status was updated",
          color: "success",
          icon: "check",
        });
      } else {
        setSnackbar({
          ...snackbar,
          status: true,
          title: "Something was wrong on update question status",
          color: "error",
          icon: "check",
        });
      }
    }

    // event?.preventDefault();
    setView(nextView);
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle, snapshot) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    // userSelect: "none",
    // padding: 16,
    // margin: "0 0 8px 0",
    // minHeight: "50px",
    // backgroundColor: snapshot.isDragging
    //   ? "#263B4A"
    //   : "#456C86",
    // color: "white",
    // position: 'relative',
    // zIndex: 5000,
    // visibility: 'visible',
    // &:hover {
    //     color: ${props => props.theme.color};
    //   }
    // pointerEvents: "none",
    ...(isDragging && {
      background: "#cccccc",
    }),
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  });

  const onDragEnd = (result) => {
    console.log("result: ", result);
    tableForOrder = reorder(tableForOrder, result?.oldIndex, result?.targetIndex);
    console.log('tableForOrder: ', tableForOrder);
    setOrderWasChanged(true);
  };

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const updateQuestionOrder = async () => {
    // console.log('tableForOrder: ', tableForOrder);
    
    const updateStatus = await updateQuestionsOrderDO(networkGlobals.host, tableForOrder);
    if (updateStatus === 200) {
      setOrderWasChanged(false);
      setRefresh(!refresh);
      addAlert(dispatch, "Question order was saved")
    }
  };

  const publishQuestionnaire = async () => {
    setPublishLoader(true);
    const publishRes = await publishQuestionnaireDO(currentHost, currentBrand, questionaireID);
    if (publishRes === 200) {
      setTimeout(() => {
        setPublishLoader(false);
        setSnackbar({
          ...snackbar,
          status: true,
          title: "Questionnaire was published",
          color: "success",
          icon: "check",
        });
      }, 1000);
    } else {
      setPublishLoader(false);
      setSnackbar({
        ...snackbar,
        status: true,
        title: "Something happenedon  publish",
        color: "error",
        icon: "error",
      });
    }
  };
  const openModal = (item, type) => {
    item ? setUpdateData(item) : setUpdateData(null);
    console.log("type: ", type);
    setAddEditModalOpenType(type);
    setAddEditModalOpenStatus(true);
  };

  const getAndPrepareTableData = async (host?, filters?) => {
    // if (!filters["brand"]) return [];
    console.log('questionaireID: ', questionaireID);
    if (!questionaireID) {
      const list = await questionnarieListDo(networkGlobals.host, filters["brand"]);
      questionaireID = list.find(q=>q.name == snakeCaseToCamelCase(bname!))?.id;
      if (!questionaireID) {
        return []
      }
    }
    setOrderWasChanged(false);
    const questionList = await questionsListDO(networkGlobals.host, questionaireID, currentBrand?.id);
    setLastOrdinal(questionList[questionList?.length - 1]?.ordinal);
    questionList.forEach((q) => {
      q.id = q.qid || q.bcid + "b";
      q.text ||= q.bctext;
    });
    // setTableData(questionList);
    tableForOrder = questionList;
    return questionList;
  };
  const enterQuestion = (e) => {
    const item = e.row;
    const linkType = item?.content_type_id === 4 ? "link" : "text";
    item?.content_type_id === 2
      ? navigate(`${questionRidirectURL}/${bname}/${Number(item?.question_id)}`)
      : openModal(item, linkType);
  };

  // const searchQuestion = (input) => {
  //     console.log('input: ', input);
  //     const res = tableData.find(v => v?.display_name == input);
  //     console.log('res: ', res ? res : "wasn't found");
  // }

  const statusElement = (item) => {
    let label = "", color= ""
    switch (item?.content_state_id) {
      case 1:
        label = "Draft";
        color = "default";
        break;
      case 2:
        label = "Published";
        color = "success";
        break;
      case 3:
        label = "Deactivated";
        color = "secondary";
        break;
    }
    return <TwoSizeChip label={label} color={color as any} />;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TablePage
        title="Questionnaire list"
        table={{
          columns,
          loadNext: getAndPrepareTableData,
          lineClick: enterQuestion,
          onRowOrderChange: onDragEnd,
        }}
        refresh={refresh}
        actions={{
          title: "Actions",
          addActions: [
            {
              label: "Text",
              onClick: () => openModal("", "text"),
              color: "secondary.light",
              variant: "contained",
              role: PermissionType.AddNew
            },
            {
              label: "Question",
              onClick: () => navigate(`${questionRidirectURL}/${bname}/new?qid=${questionaireID}`),
              color: "secondary",
              variant: "contained",
              role: PermissionType.AddNew
            },
          ],
          saveActions: [
            {
              label: "Update order",
              onClick: () => updateQuestionOrder(),
              role: PermissionType.Edit
            },
            {
              label: "Add new route rule",
              onClick: () => openModal("", "link"),
              role: PermissionType.AddNew
            },
            {
              label: "Publish",
              onClick: () => publishQuestionnaire(),
              variant: "contained",
              role: PermissionType.Publish
            },
          ],
        }}
      />
      
      <AddEditQuestionText
        addEditModalOpenStatus={addEditModalOpenStatus}
        setAddEditModalOpenStatus={setAddEditModalOpenStatus}
        updateData={updateData}
        questionaireID={questionaireID}
        brandID={currentBrand?.id}
        userID={currentUser?.id}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        lastOrdinal={lastOrdinal}
        getAndPrepareTableData={() => setRefresh(!refresh)}
        addEditModalOpenType={addEditModalOpenType}
      />
      <MDSnackbar
        color={snackbar?.color}
        icon={snackbar?.icon}
        title={snackbar?.title}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}
export default QuestionList;
