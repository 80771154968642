import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HIDDEN_ATTRIBUTES } from "utils/matchmakerUtils";
import { groupBy, snakeCaseToCamelCase } from "utils/globalsUtils";
import { loadAttributes } from "utils/attributesUtil";
import { visibiltyOptions } from "utils/attributesUtil";
import { Attribute } from "models/Attribute";
import { Introduction } from "models/Introduction";
import { useMaterialUIController } from "context";
import { PermissionType } from "models/BoRole";
import CardItem from "components/alove/CardItem";
import { getQuestionDO, questionnarieListDo } from "utils/questionsUtils";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import colors from "assets/theme/base/colors";
import { VerticalDivider } from "components/alove/VerticalDivider";

const MDTypography = require("components/MDTypography").default;

const findPrefFromBio = (attrs, bio) => {
  const attr = attrs.find((a) =>
    a.responses?.find((r) => r.bios?.find((b) => b?.bio_attr_id == bio))
  );
  if (!attr) return null;
  return attr;
};

const isAttributesMatch = (bio: Attribute, pref: Attribute, bioValue, prefValue) => {
  if (!bioValue || !prefValue) return null;
  const bioResponses = bio.responses.filter((r) => bioValue.includes(r.k));
  const prefResponses = pref.responses.filter((r) => prefValue.includes(r.k));

  return prefResponses.some((prefResponse) =>
    prefResponse.bios?.some((prefBio) =>
      bioResponses.some((bioResponse) => bioResponse.k === prefBio.bio_response_id)
    )
  );
};

const headerCell = (value) => (
  <Grid
    xs={6}
    item
    display="flex"
    justifyContent="space-between"
    flexDirection="column"
    alignItems="center"
    gap={2}
  >
    <div></div>
    <MDTypography style={{ fontSize: "16px" }} fontWeight="bold" textAlign="center" lineHeight="1">
      {value}
    </MDTypography>

    <div
      style={{
        height: "3px",
        width: "30%",
        borderRadius: "7px",
        marginBottom: "25px",
      }}
    />
  </Grid>
);

export const AttributesTable = ({
  request,
  isShidduch,
}: {
  request: Introduction;
  isShidduch: boolean;
}) => {
  const [attributes, setAttributes] = useState([] as Attribute[]);
  const [questionnaires, setQuestionnaires] = useState([] as any);
  const [cellHover, setCellHover] = useState("");
  const [folded, setFolded] = useState(true);

  useEffect(() => {
    loadAttributes().then((attrs) => {
      setAttributes(attrs);
    });

    questionnarieListDo().then((questionnaires) => {
      setQuestionnaires(questionnaires);
    });
  }, []);
  const groupped = groupBy(attributes, (x) => x.attr_group);
  const initiatorName = request.initiatorUser?.firstName;
  const responderName = request.responderUser?.firstName;

  const cell = (value: string, match: boolean | null, align: string, key?: string) => {
    let color;
    if (match !== null) {
      color = match ? "#49EFBD" : "#F4357A";
      if (cellHover != key) {
        color += "3b";
      }
    }
    return (
      <Grid
        container
        sx={{p:1}}
        bgcolor={color}
        borderRadius={1}
        item
        flexDirection={align == "right" ? "row-reverse" : "row"}
        wrap="nowrap"
        justifyContent="center"
        alignItems={"center"}
        onMouseEnter={() => setCellHover(key || "")}
        gap={2}
      >
        <MDTypography style={{ fontSize: "12px" }} lineHeight="1" textAlign={align}>
          {value}
        </MDTypography>
        {align != "center" && (
          <Grid
            item
            xs={true}
            alignItems={"center"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <Divider
              style={{
                color: "black",
                borderStyle: "dotted",
                borderWidth: "2px",
              }}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const getAttrArray = () => {
    return (groupped["bio"] || [])
      .filter((attr) => !HIDDEN_ATTRIBUTES.includes(attr.attr_name))
      .filter(
        (attr) =>
          !isShidduch ||
          [visibiltyOptions.PUBLIC.value, visibiltyOptions.SME_VISIBLE.value].includes(
            attr.default_policy
          )
      )
      .map((attr: Attribute) => {
        const initiatorBio = request.initiatorProfile?.attributesValues[attr.attr_name]?.join(",");
        const responderBio = request.responderProfile?.attributesValues[attr.attr_name]?.join(",");
        if (!initiatorBio && !responderBio) return null;

        const initiatorBioTxt = attr.responses
          ?.filter(
            (r) =>
              request.initiatorProfile?.attributesValues[attr.attr_name]?.includes(r.k) ||
              request.initiatorProfile?.attributesValues[attr.attr_name]?.includes(r.id) ||
              request.initiatorProfile?.attributesValues[attr.attr_name]?.includes(r.id.toString())
          )
          ?.map((r) => r.txt)
          .join(", ");

        const responderBioTxt = attr.responses
          ?.filter(
            (r) =>
              request.responderProfile?.attributesValues[attr.attr_name]?.includes(r.k) ||
              request.responderProfile?.attributesValues[attr.attr_name]?.includes(r.id) ||
              request.responderProfile?.attributesValues[attr.attr_name]?.includes(r.id.toString())
          )
          ?.map((r) => r.txt)
          .join(", ");

        const prefAttribute = findPrefFromBio(groupped["pref"], attr.attr_name);
        const initiatorPref =
          request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.join(", ");
        const responderPref =
          request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.join(", ");

        let initiatorPrefTxt = prefAttribute?.responses
          ?.filter(
            (r) =>
              request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.includes(r.k) ||
              request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
                r.id
              ) ||
              request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
                r.id.toString()
              )
          )
          ?.map((r) => r.txt)
          .join(", ");

        if (["Yes", "No"].includes(initiatorPrefTxt)) {
          initiatorPrefTxt = prefAttribute?.editing_question_txt + ": " + initiatorPrefTxt;
        }

        let responderPrefTxt = prefAttribute?.responses
          ?.filter(
            (r) =>
              request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.includes(r.k) ||
              request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
                r.id
              ) ||
              request.responderProfile?.attributesValues[prefAttribute?.attr_name]?.includes(
                r.id.toString()
              )
          )
          ?.map((r) => r.txt)
          .join(", ");

          
        if (["Yes", "No"].includes(responderPrefTxt)) {
          responderPrefTxt = prefAttribute?.editing_question_txt + ": " + responderPrefTxt;
        }

        const initiatorMatch = isAttributesMatch(
          attr,
          prefAttribute,
          request.initiatorProfile?.attributesValues[attr.attr_name],
          request.responderProfile?.attributesValues[prefAttribute?.attr_name]
        );

        const responderMatch = isAttributesMatch(
          attr,
          prefAttribute,
          request.responderProfile?.attributesValues[attr.attr_name],
          request.initiatorProfile?.attributesValues[prefAttribute?.attr_name]
        );

        return {
          id: attr.editing_question_id,
          bioName: attr.editing_question_txt,
          initiatorBio: initiatorBioTxt || initiatorBio?.toString(),
          responderBio: responderBioTxt || responderBio?.toString(),
          initiatorPrefernce: initiatorPrefTxt || initiatorPref?.toString(),
          responderPrefernce: responderPrefTxt || responderPref?.toString(),
          initiatorMatch,
          responderMatch,
          batchId: attr.related_batch_id,
        };
      })
      .filter((r) => r);
  };

  const line = (row) => (
    <Grid
      display="flex"
      flexDirection="row"
      gap={2}
      bgcolor={cellHover.startsWith(row.bioName) ? "#00000010" : ""}
    >
      {cell(row.bioName, null, "left")}
      {cell(row.initiatorBio, row.initiatorMatch, "center", row.bioName + "initiator")}
      {cell(row.responderPrefernce, row.initiatorMatch, "center", row.bioName + "initiator")}
      <Grid item bgcolor="grey" style={{ width: "5px", height: "50px" }} alignSelf="center"></Grid>
      {cell(row.responderBio, row.responderMatch, "center", row.bioName + "responder")}
      {cell(row.initiatorPrefernce, row.responderMatch, "center", row.bioName + "responder")}
      {cell(row.bioName, null, "right")}
    </Grid>
  );

  const tableCellCss = (match) => {
    if (match === null) return "";
    return match ? "match" : "unmatch";
  };

  return (
    <Grid>
      <Grid
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        sx={{ width: "100%" }}
        style={{ zIndex: 2, position: "relative" }}
      >
        {isShidduch ? (
          <>
            <Grid display="flex" justifyContent="center" flexDirection="row" gap={1}>
              {headerCell("")}
              {headerCell(initiatorName + "'s bio")}
              {headerCell(responderName + "'s Pref")}
              {headerCell(responderName + "'s bio")}
              {headerCell(initiatorName + "'s Pref")}
              {headerCell("")}
            </Grid>

            <Grid display="flex" flexDirection="column" gap={2} sx={{ width: "100%" }}>
              {getAttrArray()
                .slice(0, folded ? 7 : Infinity)
                .map(line)}
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              "& .unmatch": {
                color: colors.error.main,
              },
              "& .match": {
                color: colors.success.main,
              },
            }}
          >
            <DataGridPremium
              rows={getAttrArray()}
              getTreeDataPath={(row) => [
                questionnaires.find((q) => q.id == row.batchId)?.display_name || "Onboarding",
                row.bioName,
              ]}
              treeData
              columns={[
                {
                  field: "initiatorBio",
                  headerName: initiatorName + "'s Bio",
                  flex: 1,
                  cellClassName: (params) => tableCellCss(params.row.initiatorMatch),
                },
                {
                  field: "responderPrefernce",
                  headerName: responderName + "'s Preferences",
                  flex: 1,
                  cellClassName: (params) => tableCellCss(params.row.initiatorMatch),
                },
                {
                  field: "responderBio",
                  headerName: responderName + "'s Bio",
                  flex: 1,
                  cellClassName: (params) => tableCellCss(params.row.responderMatch),
                },
                {
                  field: "initiatorPrefernce",
                  headerName: initiatorName + "'s Preferences",
                  flex: 1,
                  cellClassName: (params) => tableCellCss(params.row.responderMatch),
                },
              ]}
              hideFooter
              autoHeight
              groupingColDef={{
                flex: 1,
              }}
            />
          </Box>
          // Object.entries(groupBy(getAttrArray(), (r) => r.batchId))
          //   .sort((a, b) => (a[0] ? -1 : 1))
          //   .map(([k, group]: any) => (
          //     <CardItem
          //       title={questionnaires.find((q) => q.id == k)?.display_name || "Onboarding"}
          //       id={k}
          //     >
          //       <Grid display="flex" flexDirection="column" gap={2} sx={{ width: "100%" }}>
          //         {group.map(line)}
          //       </Grid>
          //     </CardItem>
          //   ))
        )}
        {isShidduch && (
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", mt: 2 }}
            style={{ cursor: "pointer" }}
            onClick={() => setFolded(!folded)}
          >
            <MDTypography
              style={{ fontSize: "16px" }}
              fontWeight="bold"
              textAlign="center"
              lineHeight="1"
            >
              {folded ? "See All" : "Hide"}
            </MDTypography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
