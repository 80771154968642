import { ScreenCard, ScreenModel, ScreenType } from "models/Screen";
import React from "react";
import ReactDOM from "react-dom";
import { Collapse, Divider, Grid } from "@mui/material";
import iphone from "assets/images/alove/iphone.png";
import { BrandLogo } from "components/alove/BrandLogo";

const MDTypography = require("components/MDTypography/index.js").default;
const MDButton = require("components/MDButton/index.js").default;

export const ScreenPreview = ({ screen }: { screen: ScreenModel }) => {
  const fileds = {
    image: screen.image && (
      <img
        style={{
          width: "80%",
          height: "auto",
        }}
        src={screen.image}
      />
    ),
    title: screen.title && (
      <MDTypography variant="h3" align="center">
        {screen.title}
      </MDTypography>
    ),
    body: screen.body && (
      <MDTypography variant="body2" style={{ whiteSpace: "pre-line" }}>
        {screen.body}
      </MDTypography>
    ),
    button1Title: screen.button1Title && (
      <MDButton variant="contained" color="primary" fullWidth circular>
        {screen.button1Title}
      </MDButton>
    ),
    button2Title: screen.button2Title && (
      <MDButton variant="contained" color="primary" fullWidth circular>
        {screen.button2Title}
      </MDButton>
    ),
    cancelTitle: screen.cancelTitle && (
      <MDButton variant="outlined" color="primary" fullWidth circular>
        {screen.cancelTitle}
      </MDButton>
    ),
    note: screen.note && (
      <MDTypography variant="body2" align="center">
        {screen.note}
      </MDTypography>
    ),
  };

  const cardsScreen = () => {
    const [openedCard, setOpenedCard] = React.useState<number | null>(null);
    return (
      <Grid p={2} container>
        
          {screen.cards.map((card: ScreenCard, index) => {
            return (
              <Grid
                key={index}
                container
                onClick={() => setOpenedCard(openedCard === index ? null : index)}
                style={{ cursor: "pointer" }}
                flexDirection="column"

              >
                <MDTypography variant="h5">
                  {card.title}
                </MDTypography>
                <Collapse in={openedCard === index}>
                  <MDTypography variant="body1">
                    {card.body}
                  </MDTypography>
                  {card.image && (
                  <img
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                    src={card.image}
                  />)}
                </Collapse>
                <Divider />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  const getLayout = (screenType: ScreenType) => {
    switch (screenType) {
      case ScreenType.Popup:
        return (
          <Grid
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "gray",
            }}
            container
            alignContent="center"
            justifyContent="center"
          >
            <Grid
              container
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              mx={2}
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                padding: "20px",
              }}
            >
              {screen.fieldsOrder?.map((field) => fileds[field])}
            </Grid>
          </Grid>
        );
      case ScreenType.Curtain:
        return (
          <Grid
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "gray",
            }}
            container
            alignContent="end"
          >
            <Grid
              container
              alignItems="center"
              flexDirection={"column"}
              gap={2}
              justifyContent="space-evenly"
              wrap="nowrap"
              style={{
                height: "80%",
                width: "100%",
                backgroundColor: "white",
                borderRadius: "20px 20px 0 0",
                padding: "20px",
                overflow: "scroll",
              }}
            >
              {screen.fieldsOrder?.map((field) => fileds[field])}
            </Grid>
          </Grid>
        );

      case ScreenType.FullScreen:
      case ScreenType.SliderScreen:
        return (
          <Grid
            justifyContent="space-evenly"
            alignItems="center"
            flexDirection={"column"}
            wrap="nowrap"
            container
            style={{
              height: "100%",
              width: "100%",
              overflow: 'scroll'
            }}
            px={1}
          >
            {screen.fieldsOrder?.map((field) => fileds[field])}
          </Grid>
        );
      case ScreenType.CardsScreen:
        return cardsScreen();
      default:
        return "Error";
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${iphone})`,
        backgroundSize: "cover",
        width: "349px",
        height: "700px",
        padding: "80px 20px 80px 22px",
      }}
    >
      {getLayout(screen.type)}
    </div>
  );
};
