import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid, Card, Divider, Checkbox, TextField, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import {
  DataGridPremium,
  GridToolbar,
  gridClasses,
  GridActionsCellItem,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridTreeDataGroupingCell,
} from "@mui/x-data-grid-premium";
import { useDemoData } from "@mui/x-data-grid-generator";
import selectData from "./data/data";
import { Link } from "react-router-dom";
import AddNewRole from "./addNewRoleModal";
import AddNewRoleType from "./addNewRoleTypeModal";
import EditRoleModal from "./editRoleModal";
import CopyRoleModal from "./copyRoleModal";
import DeleteRoleModal from "./deleteRoleModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { getSystemUsersDO } from "utils/systemUsersUtils";
import { filterToMyRoles, getRolesDO, getRolesType, updateRoleDo } from "utils/rolesUtils";
import { useMaterialUIController, setCurrentUser } from "context";
import { TablePage } from "components/alove/TablePage";
import { PermissionType } from "models/BoRole";
import { element } from "prop-types";

function ViewRoles() {
  const pageNumber = "2.5";
  const navigate = useNavigate();

  const [controller, dispatch] = useMaterialUIController();
  const { currentUser, currentBrand, currentRole } = controller;
  console.log("currentBrand~~~~~~~~~~: ", currentBrand);
  const [roles, setRoles] = useState([]);
  const [rolesTypes, setRolesTypes] = useState([]);
  const [snackbar, setSnackbar] = useState({
    status: false,
  });
  const apiRef = useGridApiRef();
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const visibleFields = ["commodity"];

  const columns = [
    {
      field: "id",
      hidden: () => true,
    },

    {
      field: "description",
      headerName: "Role Description",
      flex: 1,
    },
    {
      field: "manager",
      headerName: "Type Manager",
      width: 150,
      type: "boolean",
      align: "center",
      hidden: (params) =>
        !currentRole?.hasAccess(pageNumber, PermissionType.Manager) || !currentBrand.isAlove,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      align: "center",
      renderCell: (params) => {
        if (Number.isInteger(params?.id)) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditRoleModal
                    row={params}
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                    getRoles={prepareRows}
                  />
                </Tooltip>
              }
              label="Edit"
              //   onClick={}
            />,
            currentBrand.isAlove && currentRole?.hasAccess(pageNumber, PermissionType.Duplicate) && (
              <GridActionsCellItem
                icon={
                  <Tooltip title="Edit">
                    <CopyRoleModal
                      row={params}
                      snackbar={snackbar}
                      setSnackbar={setSnackbar}
                      getRoles={() => setRefresh(!refresh)}
                      currentUser={currentUser}
                    />
                    {/* <ContentCopyIcon /> */}
                  </Tooltip>
                }
                label="Copy"
                //   onClick={}
              />
            ),
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <DeleteRoleModal
                    row={params}
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                    getRoles={() => setRefresh(!refresh)}
                  />
                </Tooltip>
              }
              label="Delete"
              //   onClick={}
              // showInMenu
            />,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [currentBrand?.id]);

  const prepareRows = async () => {
    const roles = await getRolesDO("dev", [currentBrand?.id]);
    console.log("a");
    setRoles(roles);
    let rows = [];
    let row = {};
    filterToMyRoles(roles, currentUser, currentRole).map(async (item) => {
      row = {};
      row.id = item?.id;
      row.commodity = item?.type;
      row.role = item?.role_name;
      row.manager = item?.is_manager;
      row.description = item?.description;
      rows?.push(row);
    });
    setRows(rows);

    return rows;
  };

  // const navigate = useNavigate();

  const getData = async () => {
    setRolesTypes(await getRolesType("dev"));
  };

  const closeSnackBar = () => setSnackbar({ ...snackbar, status: false });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const processRowUpdate = (newRow) => {
    const role = roles.find((item) => item.id === newRow.id);
    role.is_manager = newRow.manager;
    updateRoleDo("dev", role, newRow.id);
    return newRow;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TablePage
        title={currentBrand?.name + " - Roles"}
        refresh={refresh}
        table={{
          columns,
          loadNext: prepareRows,
          lineClick: (params, event) => {
            if (Number.isInteger(params?.id)) {
              navigate(`/roles/${params?.id}`);
            }
          },
          processRowUpdate: processRowUpdate,
          getTreeDataPath: (row) => [row.commodity, row.role],
          groupingColDef: {
            minWidth: 300,
          },
        }}
        actions={{
          title: "Roles Actions",
          page: pageNumber,
          addActions: [
            {
              role: PermissionType.Manager,
              element: (
                <AddNewRoleType
                  snackbar={snackbar}
                  setSnackbar={setSnackbar}
                  currentUser={currentUser}
                  prepareRows={prepareRows}
                  prepareRows={() => setRefresh(!refresh)}
                  getData={getData}
                />
              ),
            },
            {
              role: PermissionType.AddNew,
              element: (
                <AddNewRole
                  snackbar={snackbar}
                  setSnackbar={setSnackbar}
                  prepareRows={() => setRefresh(!refresh)}
                  rolesTypes={rolesTypes.filter((item) =>
                    rows?.find(
                      (row) =>
                        row?.commodity === item?.name ||
                        currentRole?.hasAccess(pageNumber, PermissionType.Manager)
                    )
                  )}
                  currentUser={currentUser}
                  brandID={currentBrand?.id}
                />
              ),
            },
          ],
        }}
      />

      <MDSnackbar
        color={snackbar?.color ? snackbar?.color : "success"}
        icon={snackbar?.icon ? snackbar?.icon : "check"}
        title={snackbar?.title ? snackbar?.title : "success"}
        content=""
        dateTime=""
        open={snackbar.status}
        onClose={closeSnackBar}
        close={closeSnackBar}
      />
    </DashboardLayout>
  );
}

export default ViewRoles;
